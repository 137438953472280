import { useEffect, useState } from "react";


interface CountryCodeSelectorProps {
    selectedCode: string;
    onCodeChange: (code: string) => void;
  }
  
  interface Country {
    name: string;
    callingCodes: string[];
    flags: {
      svg: string;
      png: string;
    };
  }
  
  const CountryCodeSelector: React.FC<CountryCodeSelectorProps> = ({ selectedCode, onCodeChange }) => {
    const [countryCodes, setCountryCodes] = useState<Country[]>([]);
  
    useEffect(() => {
      fetch('https://restcountries.com/v2/all?fields=name,callingCodes,flags')
        .then(response => response.json())
        .then(data => setCountryCodes(data))
        .catch(error => console.error(error));
    }, []);
  
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      onCodeChange(event.target.value);
    };
  
    const selectedCountry = countryCodes.find(country =>
      country.callingCodes.includes(selectedCode.replace('+', ''))
    );
  
    return (
      <div className="country-code d-flex align-items-center px-3">
        <div className="country-flag">
          {selectedCountry && <img src={selectedCountry.flags.svg} alt="flag" />}
        </div>
        <div className="country-code-text">
          <select value={selectedCode}
          onChange={handleChange} style={{maxWidth: "64px"}}>
            {countryCodes.map((country) =>
              country.callingCodes.map((code) => (
                <option key={code} value={`+${code}`}>
                  {`+${code} (${country.name})`}
                </option>
              ))
            )}
          </select>
        </div>
      </div>
    );
  };
  

  
    export default CountryCodeSelector;     // export default CountryCodeSelector;